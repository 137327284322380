<template>
  <div class="content m-auto">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="用户名:" label-for="input-1">
        <b-form-input id="input-1" v-model="form.username" type="text" required placeholder="用户名"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="密码:" label-for="input-2">
        <b-form-input id="input-2" v-model="form.password" required placeholder="密码" type="password"></b-form-input>
      </b-form-group>
      <div class="buttons text-center">
        <b-button class="button" type="submit" variant="primary">登录</b-button>
        <b-button class="button" type="reset" variant="danger">重填</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
  import util from '../../util.js';
  export default {
    data() {
      return {
        form: {
          username: '',
          password: '',
        },
        show: true
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()
        // console.log(JSON.stringify(this.form))
        let apiUrl = util.serverUrl + '/user/login';
        this.$http.post(apiUrl, this.form, {
          emulateJSON: true
        }).then(function (res) {
          console.log(1)
          console.log(res.body);
          this.$router.push('/')
        }, function (res) {
          console.log(2)
          console.log(res.body);
          if (res.status == 400) {
            alert(res.body.msg);
          }
        });
        console.log('end')
      },
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>
<style>
  .content {
    width: 440px;
    margin-top: 80px !important;
  }

  .buttons>.button {
    margin-right: 20px;
  }
</style>